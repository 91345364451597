
import { Component, Vue } from 'vue-property-decorator'
import { OrderDeploy, orderTypeInfo } from '../../types/workOrder.d'
import { ElForm } from 'element-ui/types/form'
import { validateMinLength } from '../../utils/validate'

@Component
export default class extends Vue {
  private searchInfo = {
    orderTypeName: '',
    orderTypeStatus: ''
  }

  private tableData = {
    loading: false,
    tr: [
      {
        label: '工单类型',
        prop: 'orderTypeName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '归属项目',
        prop: 'projectName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '反馈人角色',
        prop: 'roleOwnName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '审核人角色',
        prop: 'roleTwoName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '状态',
        prop: 'orderTypeStatus',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '创建人',
        prop: 'createUserName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '创建时间',
        prop: 'ctime',
        minWidth: '100',
        showOverflowTooltip: true
      }
    ],
    data: []
  }

  private total = 0
  private page = 1
  private size = 10
  private editShow = false
  private isShow = false
  private isAdding = false
  private roleList: Array<{ userId: string; userName: string }> = []
  private addForm: orderTypeInfo | OrderDeploy = {
    orderTypeName: '',
    projectIdList: [],
    roleOwnIdList: [],
    roleTwoIdList: []
  }

  private addRules = {
    orderTypeName: [
      { required: true, message: '请输入工单类型名称', trigger: 'change' },
      { validator: validateMinLength, message: '工单类型最少为4个字符', trigger: ['blur', 'change'] }
    ],
    projectIdList: [
      { required: true, message: '请选择所属项目（多选）', trigger: 'change' }
    ],
    roleOwnIdList: [
      { required: true, message: '请选择反馈人角色（多选）', trigger: 'change' }
    ],
    roleTwoIdList: [
      { required: true, message: '请选择审核人角色（多选）', trigger: 'change' }
    ]
  }

  get normalProjectList () {
    return this.$store.state.normalProjectList
  }

  created () {
    this.getData()
  }

  getData () {
    this.tableData.loading = true
    this.$axios.get(this.$apis.workOrder.selectWorkOrderTypeByList, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    }).then((res) => {
      this.total = res.total || 0
      this.tableData.data = res.list || []
    }).finally(() => {
      this.tableData.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // 新增
  onAdd () {
    this.addForm = {
      orderTypeName: '',
      projectIdList: [],
      roleOwnIdList: [],
      roleTwoIdList: []
    }
    this.editShow = true
    this.isShow = true
    // 获取人员列表
    this.getRoleList()
  }

  // 编辑
  onEdit (orderType: string) {
    this.editShow = true
    this.getRoleList()
    this.getDetail(orderType)
    this.isShow = true
  }

  // 获取详情
  getDetail (orderType: string) {
    this.$axios.get(this.$apis.workOrder.selectWorkOrderTypeByType, {
      orderType: orderType
    }).then(res => {
      this.addForm = res || {}
    })
  }

  // 关闭添加弹框
  closeAdd () {
    this.$nextTick(() => {
      this.$refs.addForm && (this.$refs.addForm as ElForm).clearValidate()
    })
    this.editShow = false
    this.isShow = false
  }

  addInspection () {
    (this.$refs.addForm as ElForm).validate(valid => {
      if (valid) {
        this.isAdding = true
        const url = this.addForm.orderType ? this.$apis.workOrder.updateWorkOrderType : this.$apis.workOrder.insertWorkOrderType
        this.$axios.post(url, {
          ...this.addForm
        }).then(() => {
          this.$message.success('添加成功')
          this.closeAdd()
          this.onSearch()
        }).finally(() => {
          this.isAdding = false
        })
      }
    })
  }

  getRoleList () {
    this.$axios.get(this.$apis.common.getRoleList).then((res) => {
      this.roleList = res
    })
  }

  onDetail (order: OrderDeploy) {
    this.addForm = order
    this.editShow = false
    this.isShow = true
  }

  getNameLit (nameList: string) {
    if (nameList) {
      return nameList.split(',')
    }
  }

  onSwitch (row: OrderDeploy) {
    const txt = row.orderTypeStatus === '1' ? '禁用' : '启用'
    this.$confirm('确认' + txt + '吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.workOrder.onOffWorkOrderType, {
        orderType: row.orderType
      }).then(() => {
        this.$message.success(txt + '成功')
        this.onSearch()
      })
    })
  }

  onDelete (orderType: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.workOrder.deleteDataWorkOrderType, {
        orderType: orderType
      }).then(() => {
        this.$message.success('删除成功')
        this.onSearch()
      })
    })
  }
}
